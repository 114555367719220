<template>
  <div class="field">
    <div class="top_area bg_grey">
      <div class="inner_width">
        <div class="top_title">領域概念</div>
        <div class="search_area">
          <v-form>
            <v-row>
              <v-col cols="12" md="3">
                <v-select :items="fieldSelectItems" v-model="form.type" flat dense outlined solo hide-details clearable label="請選擇類別"></v-select>
              </v-col>
              <v-col cols="9" md="8">
                <v-text-field v-model="form.lexicon" dense flat solo outlined hide-details clearable placeholder="請輸入目標設計對象或場域" @keyup.enter="sendForm"></v-text-field>
              </v-col>
              <v-col cols="3" md="1" class="px-0">
                <v-btn color="grey white--text" depressed style="height:40px" @click="sendForm">
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </div>
    </div>
    <div class="content_area">
      <div class="inner_width">
        <template>
          <div class="text-center" v-show="showResultMessage">{{ serchResultMessage }}</div>
        </template>
        <template v-if="showResultData">
          <v-card-subtitle class="px-0">請選擇與 <span class="text_brown px-1">{{ fieldText }}</span> 相關的領域概念</v-card-subtitle>
          <v-card class="v-card__text white elevation-5 rounded pa-5">
            <v-row>
              <v-col cols="6" md="3" sm="4" class="text-center" v-for="item in searchResutData" :key="item">
                <v-btn class="bg_grey_light w-100" color="white--text" depressed>{{ item }}</v-btn>
              </v-col>
            </v-row>
          </v-card>
        </template>
      </div>
    </div>
    <div class="bottom_nav clearfix">
      <div class="bottom_nav_inner">
        <div class="btn_large">
          <v-btn class="bg_brown w-100 small" color="white--text" depressed @click="openCheckModal">確定製作</v-btn>
        </div>
      </div>
      <div class="bottom_nav_block">
        <div class="btn_small"><v-btn class="bg_grey small w-100 pa-2" color="white--text" depressed><v-icon>mdi-arrow-up</v-icon></v-btn></div>
        <div class="btn_small"><v-btn class="bg_brown small w-100 pa-2" color="white--text" depressed><v-icon>mdi-folder-heart-outline</v-icon></v-btn></div>
      </div>
    </div>
    <!-- modal -->
    <v-dialog v-model="buildToolModal" width="236px">
      <v-card>
        <v-col class="pa-4">
          <p>儲存到</p>
          <v-row>
            <v-col cols="12" class="py-2">
              <v-checkbox v-model="selected" class="float-left mt-0" style="width:30px"></v-checkbox>
              <span class="float-left mt-1 me-1" style="width: calc(100% - 60px)">我的工具包</span>
              <span class="float-right mt-1" style="width:25px"><v-icon>mdi-lock</v-icon></span>
            </v-col>
            <v-col cols="12" class="py-2">
              <v-checkbox v-model="selected" class="float-left mt-0" style="width:30px"></v-checkbox>
              <span class="float-left mt-1 me-1" style="width: calc(100% - 60px)">我的工具包十六字我的工具包十六字</span>
              <span class="float-right mt-1" style="width:25px"><v-icon>mdi-lock</v-icon></span>
            </v-col>
            <hr width="100%" class="my-2">
            <v-col cols="12" class="py-2">
              <span class="red--text">*</span>工具包名稱
              <v-text-field dense flat solo outlined clearable :counter="16" class="mt-1"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-checkbox v-model="selected" label="不公開" class="mt-0"></v-checkbox>
            </v-col>
            <v-col cols="12">
              <v-btn class="bg_brown w-100" color="white--text" depressed @click="closeCheckModal">建立</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'Field',
  data: () => ({
    form: {
      lexicon: '',
      type: ''
    },
    fieldText: '',
    selected: '',
    fieldSelectItems: [
      {
        value: 'PROD',
        text: '產品'
      },
      {
        value: 'LOC',
        text: '場域'
      },
      {
        value: 'PER',
        text: '角色'
      }
    ],
    serchResultMessage: '請於上方輸入欲搜尋的概念詞',
    showResultMessage: true,
    showResultData: false,
    searchResutData: [],
    buildToolModal: false,
    radios: null
  }),
  created () {

  },
  computed: {
    ...mapGetters('field', ['fieldKeywordGetter'])
  },
  methods: {
    ...mapActions('field', ['showFieldKeywordData']),
    sendForm () {
      event.preventDefault()
      this.fieldText = this.form.lexicon
      this.showResultMessage = false
      this.showResultData = false
      this.showFieldKeywordData(this.form)
        .then(res => {
          if (res.status === 200) {
            this.searchResutData = res[this.form.type]
            this.showResultMessage = false
            this.showResultData = true
          } else if (res.status === 404) {
            this.showResultMessage = true
            this.showResultData = false
            this.serchResultMessage = `很抱歉，目前還未有 ${this.fieldText} 的相關資料`
          } else {
            this.showResultMessage = true
            this.showResultData = false
            this.serchResultMessage = res.message || '資訊錯誤:' + res.status
          }
        })
        .catch(res => {
          this.showResultMessage = true
          this.serchResultMessage = res.message || '伺服器發生錯誤:' + res.status
        })
    },
    openCheckModal () {
      this.buildToolModal = true
    },
    closeCheckModal () {
      this.buildToolModal = false
    }
  }
}
</script>
