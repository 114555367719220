import axios from 'axios'
import getEnv from '@/utils/env'
const CLIENT_API_URL = `${getEnv('VUE_APP_BASE_URL')}`
const axiosinstance = axios.create({
  baseURL: CLIENT_API_URL
})

axiosinstance.interceptors.request.use(
  function (config) {
    return config
  },
  function (error) {
    console.log(error)
    // Do something with request error
    return Promise.reject(error)
  }
)

axiosinstance.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    console.log('error', error.response)
    if (error.response) {
      switch (error.response.status) {
        case 404:
          console.log('你要找的頁面不存在')
          // go to 404 page
          break
        case 500:
          console.log('程式發生問題')
          // go to 500 page
          break
        default:
          console.log(error.message)
      }
    }

    return Promise.reject(error)
  }
)

export default axiosinstance
