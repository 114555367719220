import {
  getFieldKeywordApi
} from '@/api/field'

export const state = () => ({
  fieldKeywordData: []
})

export const getters = {
  fieldKeywordGetter: state => state.fieldKeywordData
}

export const actions = {
  async showFieldKeywordData ({ commit }, params) {
    const fieldKeywordData = await getFieldKeywordApi(params)
    return fieldKeywordData
  }
}

export const mutations = {

}

export const namespaced = true
