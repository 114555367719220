<template>
  <div class="mood">
    <div class="top_area bg_grey">
      <div class="inner_width">
        <div class="top_title">情緒概念</div>
        <div class="search_area">
          <v-row>
            <v-col cols="9" md="11">
              <v-text-field v-model="form.lexicon" dense solo outlined hide-details clearable placeholder="請輸入一個概念詞" @keyup.enter="sendForm"></v-text-field>
            </v-col>
            <v-col cols="3" md="1" class="px-0">
              <v-btn color="grey white--text" depressed style="height:40px" @click="sendForm">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
    <div class="content_area">
      <div class="inner_width">
        <template>
          <div class="text-center mb-5" v-show="showResultMessage">{{ serchResultMessage }}</div>
        </template>
        <template v-if="showResultData">
          <v-card-subtitle class="px-0" v-show="!showResultMessage">請選擇與 <span class="text_brown px-1">{{ fieldText }}</span> 相關的情緒</v-card-subtitle>
          <v-row>
            <v-col cols="6">
              <v-card class="v-card__text white elevation-5 rounded pa-5">
                <p>{{ posText }}</p>
                <v-row>
                  <template v-if="this.searchSimilarData.length === 0"><span class="py-5 px-3">目前無相關資料</span></template>
                  <template v-else>
                    <v-col cols="12" md="6" class="text-center" v-for="item in searchSimilarData" :key="item">
                      <v-btn class="bg_grey_light w-100" color="white--text" depressed>{{ item }}</v-btn>
                    </v-col>
                  </template>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-card class="v-card__text white elevation-5 rounded pa-5">
                <p>{{ negText }}</p>
                <v-row>
                  <template v-if="this.searchDiffData.length === 0"><span class="py-5 px-3">目前無相關資料</span></template>
                  <template v-else>
                    <v-col cols="12" md="6" class="text-center" v-for="item in searchDiffData" :key="item">
                      <v-btn class="bg_grey_light w-100" color="white--text" depressed>{{ item }}</v-btn>
                    </v-col>
                  </template>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </template>
      </div>
    </div>
    <div class="bottom_nav clearfix">
      <div class="bottom_nav_inner">
        <div class="btn_large">
          <v-btn class="bg_brown w-100 small" color="white--text" depressed @click="openCheckModal">確定製作</v-btn>
        </div>
      </div>
      <div class="bottom_nav_block">
        <div class="btn_small"><v-btn class="bg_grey small w-100 pa-2" color="white--text" depressed><v-icon>mdi-arrow-up</v-icon></v-btn></div>
        <div class="btn_small"><v-btn class="bg_brown small w-100 pa-2" color="white--text" depressed><v-icon>mdi-folder-heart-outline</v-icon></v-btn></div>
      </div>
    </div>
    <!-- modal -->
    <v-dialog v-model="buildToolModal" width="236px">
      <v-card>
        <v-col class="pa-4">
          <p>儲存到</p>
          <v-row>
            <v-col cols="12" class="py-2">
              <v-checkbox v-model="selected" class="float-left mt-0" style="width:30px"></v-checkbox>
              <span class="float-left mt-1 me-1" style="width: calc(100% - 60px)">我的工具包</span>
              <span class="float-right mt-1" style="width:25px"><v-icon>mdi-lock</v-icon></span>
            </v-col>
            <v-col cols="12" class="py-2">
              <v-checkbox v-model="selected" class="float-left mt-0" style="width:30px"></v-checkbox>
              <span class="float-left mt-1 me-1" style="width: calc(100% - 60px)">我的工具包十六字我的工具包十六字</span>
              <span class="float-right mt-1" style="width:25px"><v-icon>mdi-lock</v-icon></span>
            </v-col>
            <hr width="100%" class="my-2">
            <v-col cols="12" class="py-2">
              <span class="red--text">*</span>工具包名稱
              <v-text-field dense solo outlined clearable :counter="16" class="mt-1"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-checkbox v-model="selected" label="不公開" class="mt-0"></v-checkbox>
            </v-col>
            <v-col cols="12">
              <v-btn class="bg_brown w-100" color="white--text" depressed @click="closeCheckModal">建立</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'Mood',
  data: () => ({
    form: {
      lexicon: ''
    },
    fieldText: '',
    selected: '',
    serchResultMessage: '請於上方輸入欲搜尋的概念詞',
    posText: '正向情緒',
    negText: '負向情緒',
    showResultMessage: true,
    showResultData: false,
    // searchResutData: [],
    POS_SENT: [],
    NEG_SENT: [],
    searchSimilarData: [],
    searchDiffData: [],
    buildToolModal: false,
    radios: null
  }),
  created () {
    this.creatPOSForm()
    this.creatNEGForm()
  },
  computed: {
    ...mapGetters('common', ['commonGetter'])
  },
  methods: {
    ...mapActions('design', ['showDesignData']),
    ...mapActions('common', ['showCommonData']),
    creatPOSForm () {
      this.showResultMessage = true
      this.showResultData = true
      this.showCommonData({ type: 'POS_SENT' })
        .then(res => {
          if (res.status === 200) {
            this.showResultMessage = true
            this.showResultData = true
            this.searchSimilarData = res.lexicons
          } else if (res.status === 404) {
            this.showResultMessage = true
            this.showResultData = false
            this.serchResultMessage = '無預設資料'
          } else {
            this.showResultMessage = true
            this.showResultData = false
            this.serchResultMessage = res.message || '資訊錯誤:' + res.status
          }
        })
        .catch(res => {
          this.showResultMessage = true
          this.serchResultMessage = res.message || '伺服器發生錯誤:' + res.status
        })
    },
    creatNEGForm () {
      this.showResultMessage = true
      this.showResultData = true
      this.showCommonData({ type: 'NEG_SENT' })
        .then(res => {
          if (res.status === 200) {
            this.showResultMessage = true
            this.showResultData = true
            this.searchDiffData = res.lexicons
          } else if (res.status === 404) {
            this.showResultMessage = true
            this.showResultData = false
            this.serchResultMessage = '無預設資料'
          } else {
            this.showResultMessage = true
            this.showResultData = false
            this.serchResultMessage = res.message || '資訊錯誤:' + res.status
          }
        })
        .catch(res => {
          this.showResultMessage = true
          this.serchResultMessage = res.message || '伺服器發生錯誤:' + res.status
        })
    },
    sendForm (event) {
      event.preventDefault()
      this.fieldText = this.form.lexicon
      this.showDesignData(this.form)
        .then(res => {
          if (res.status === 200) {
            this.searchSimilarData = res.Similar
            this.searchDiffData = res.Diff
            this.showResultMessage = false
            this.showResultData = true
            this.posText = '相似情緒'
            this.negText = '相異情緒'
          } else if (res.status === 404) {
            this.showResultMessage = true
            this.showResultData = false
            this.serchResultMessage = `很抱歉，目前還未有 ${this.fieldText} 的相關資料`
          } else {
            this.showResultMessage = true
            this.showResultData = false
            this.serchResultMessage = res.message || '資訊錯誤:' + res.status
          }
        })
        .catch(res => {
          this.showResultMessage = true
          this.serchResultMessage = res.message || '伺服器發生錯誤:' + res.status
        })
    },
    openCheckModal () {
      this.buildToolModal = true
    },
    closeCheckModal () {
      this.buildToolModal = false
    }
  }
}
</script>
