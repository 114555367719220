import Vue from 'vue'
import VueRouter from 'vue-router'
import Field from '@/views/Field.vue'
import Mood from '@/views/Mood.vue'
import Value from '@/views/Value.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/Field'
  },
  {
    path: '/Field',
    name: 'Field',
    component: Field
  },
  {
    path: '/Mood',
    name: 'Mood',
    component: Mood
  },
  {
    path: '/Value',
    name: 'Value',
    component: Value
  }
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

export default router
