import Vue from 'vue'
import Vuex from 'vuex'
import * as field from '@/store/field'
import * as common from '@/store/common'
import * as design from '@/store/design'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    field,
    common,
    design
  }
})
