import {
  getDesignApi
} from '@/api/design'

export const state = () => ({
  designData: []
})

export const getters = {
  designGetter: state => state.designData
}

export const actions = {
  async showDesignData ({ commit }, params) {
    const designData = await getDesignApi(params)
    return designData
  }
}

export const mutations = {

}

export const namespaced = true
