<template>
  <v-app>
    <!--側邊導覽列-->
    <v-navigation-drawer v-model="sideNav" temporary>
      <v-list dense>
        <v-list-item-group
          color="primary"
        >
          <v-list-item
            v-for="item in menuItem"
            :key="item.title"
            v-model="item.active"
          >
            <v-list-item-content>
              <div :to="item.link" @click="reloadPage">{{ item.title }}</div>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <!--主導覽列-->
    <v-toolbar class="bg_grey" dark style="position:fixed; top:0; width: 100%; z-index: 1">
      <v-btn dark text @click="sideNav=!sideNav" class="mobile_menu_bt">
        <v-icon size="18" class="mr-2" fab>mdi-menu</v-icon>
      </v-btn>
      <v-toolbar-items class="hidden-sm-and-down" >
        <v-btn v-for="item in menuItem" :key="item.title" router :to="item.link" @click="reloadPage">
          {{ item.title }}
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-main style="position:absolute; top: 56px; width:100%">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  data () {
    return {
      sideNav: false,
      menuItem: [
        { id: 1, title: '領域', link: '/Field' },
        { id: 2, title: '價值', link: '/Value' },
        { id: 3, title: '情緒', link: '/Mood' }
      ]
    }
  },
  methods: {
    reloadPage () {
      window.location.reload()
    }
  }
}
</script>
