import {
  getCommonApi
} from '@/api/common'

export const state = () => ({
  commonData: []
})

export const getters = {
  commonGetter: state => state.commonData
}

export const actions = {
  async showCommonData ({ commit }, params) {
    const commonData = await getCommonApi(params)
    return commonData
  }
}

export const mutations = {

}

export const namespaced = true
